import React from "react"
import News from "../../../blocks/en/news_page"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "sorokins_latvia_2" }) {
    ...NewsInfoEn
  }
}`

export default class NewsTextEn extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Right back FC RFS (Riga) Vladislavs Sorokins has been called up to the National Team of Latvia to participate in two official matches of the League of Nations. The opponents of the Latvian National Team will be Nationals Teams Andorra and Malta.</p>
        </div>
      </div>
    )
  }
}